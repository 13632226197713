<template>
  <div>
    <table v-if="items.data && items.data.length">
      <tbody>
      <tr v-for="item in items" :key="'vov' + item.id">
        <td class="px-2 py-2 text-emerald-600 font-medium flex items-center space-x-2">
          <router-link :to="{name: 'DiscussionDetail', params: {id: discussion.id, slug: discussion.slug}}">
            <h3 class="">
              Chương trình {{ item.channel }} ngày {{ item.date }}/{{ item.month }}/{{ item.year }}
            </h3>
          </router-link>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import ApiService from "../../core/services/api.service";

export default {
  name: "VovDailyList",
  data() {
    return {
      items: {
        data: []
      }
    }
  },
  methods: {
    loadVovDailies() {
      let query = `query {
        vovDailies(first: 5, orderBy: [{column: "id", order: DESC}]) {
          data {
            id
            date
            month
            year
            channel
          }
        }
      }`;

      ApiService.graphql(query)
          .then(({data}) => {
            if (data.data && data.data.vovDailies && data.data.vovDailies.data) {
              this.items = data.data.vovDailies.data;
            }
          })
          .catch((response) => {
            console.log(response);
          });
    }
  },
  mounted() {
    this.loadVovDailies();
  }
}
</script>
